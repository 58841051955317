@charset "UTF-8";
/*!

┬ ┬┌─┐  ┌─┐┌─┐┌─┐┌─┐┌─┐┬┌─┐┌┬┐┌─┐┌─┐
│││├┤   ├─┤└─┐└─┐│ ││  │├─┤ │ ├┤ └─┐
└┴┘└─┘  ┴ ┴└─┘└─┘└─┘└─┘┴┴ ┴ ┴ └─┘└─┘

@2018 ~ ChannelWeb
*/
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/* FONTS */
/* COLORS */
/*
** LAYOUT
*/
body {
  overflow-x: hidden;
  font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  background-color: #fff;
  color: #8e959e;
  font-weight: 300;
  text-align: left; }

* {
  outline: none;
  box-sizing: border-box; }

a {
  cursor: pointer;
  text-decoration: none; }

.color-brand-1 {
  color: #6c6863; }

.color-brand-2 {
  color: #ff6658; }

.color-brand-3 {
  color: #009cc7; }

.color-white {
  color: #fff; }

.color-black {
  color: #000; }

[v-cloak] {
  display: none; }

main {
  margin: 0 auto;
  max-width: 48rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh; }

html.is-clipped {
  overflow: hidden !important; }

.main-header {
  padding: 2rem; }
  @media all and (min-width: 768px) {
    .main-header {
      padding: 3rem; } }

.main-section {
  padding: 0 2rem 2rem; }
  @media all and (min-width: 768px) {
    .main-section {
      padding: 0 3rem 3rem; } }

.main-header nav {
  display: flex;
  flex-wrap: wrap; }
  .main-header nav div {
    padding-top: 17px;
    flex-basis: 50%; }
  .main-header nav figure {
    padding-right: 3rem;
    flex-basis: 100%;
    flex-grow: 1;
    flex-shrink: 0;
    margin-bottom: 1rem; }
    .main-header nav figure a {
      display: flex;
      width: 100%; }
      .main-header nav figure a img {
        display: block;
        width: auto;
        max-height: 28px; }
        @media all and (min-width: 376px) {
          .main-header nav figure a img {
            max-height: 31px; } }
  @media all and (min-width: 768px) {
    .main-header nav {
      flex-wrap: nowrap; }
      .main-header nav figure {
        flex-basis: 20%; }
        .main-header nav figure a {
          justify-content: flex-end; }
      .main-header nav div {
        flex-basis: 40%; }
      .main-header nav > * {
        margin-bottom: 0 !important; } }

.main-section {
  display: flex;
  align-items: flex-start;
  flex-grow: 1; }
  @media all and (min-width: 768px) {
    .main-section .article {
      padding-left: 20%; } }
  .main-section aside {
    visibility: hidden; }
    @media all and (min-width: 768px) {
      .main-section aside {
        width: 20%; } }

.main-footer {
  display: flex;
  justify-content: center;
  padding: 1rem 2rem 2rem; }
  .main-footer nav.footer-links {
    display: flex;
    flex-direction: column; }
    .main-footer nav.footer-links > div {
      display: flex;
      align-items: baseline;
      justify-content: space-between; }

.full-intro {
  z-index: 100;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #fff;
  opacity: 1;
  transition: opacity .5s ease-out;
  cursor: pointer; }
  .full-intro figure {
    position: absolute;
    top: 20px;
    left: 31px;
    height: 42px;
    max-width: calc(100% - 60px); }
    @media all and (min-width: 376px) {
      .full-intro figure {
        top: 21px;
        left: 31px;
        height: 42px; } }
    @media all and (min-width: 768px) {
      .full-intro figure {
        left: 50%;
        top: 37px;
        transform: translateX(-330px) translateY(0); } }
    .full-intro figure img {
      height: 100%;
      max-width: 100%;
      width: auto; }
  .full-intro.hiding {
    opacity: 0; }

a {
  color: #8e959e; }
  a.on {
    color: #495057; }
  a[href]:hover {
    color: #009cc7; }
  a:not([href]):hover {
    cursor: not-allowed; }

.main-section li, .main-header li {
  line-height: 1rem;
  margin-bottom: .5rem; }

.main-header div[role='menu'] .submenu {
  margin-top: 1rem; }
  .main-header div[role='menu'] .submenu a, .main-header div[role='menu'] .submenu span {
    font-size: 11px; }
    .main-header div[role='menu'] .submenu a.on, .main-header div[role='menu'] .submenu span.on {
      color: #495057; }
    .split .main-header div[role='menu'] .submenu a.title, .split .main-header div[role='menu'] .submenu span.title {
      display: none; }

.main-section .gallery-container {
  position: relative;
  width: 100%; }
  .main-section .gallery-container .swiper-button-prev {
    transform: translateX(-84px); }
  .main-section .gallery-container .swiper-button-next {
    transform: translateX(84px); }
  .main-section .gallery-container .gallery .caption {
    text-align: center;
    margin-top: 0.5rem; }
  .main-section .gallery-container .gallery .swiper-slide {
    padding: 0 1px; }
    .main-section .gallery-container .gallery .swiper-slide .image {
      width: 100%;
      padding-bottom: 100%;
      background-size: contain; }
  .main-section .gallery-container .gallery .swiper-lazy-preloader {
    left: 0;
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    top: 0;
    background-image: url("data:image/svg+xml;charset=utf-8;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCiAgICAgd2lkdGg9IjI0cHgiIGhlaWdodD0iMzBweCIgdmlld0JveD0iMCAwIDI0IDMwIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA1MCA1MDsiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KICAgIDxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSI0IiBoZWlnaHQ9IjEwIiBmaWxsPSIjZmY2NjU4Ij4NCiAgICAgIDxhbmltYXRlVHJhbnNmb3JtIGF0dHJpYnV0ZVR5cGU9InhtbCINCiAgICAgICAgYXR0cmlidXRlTmFtZT0idHJhbnNmb3JtIiB0eXBlPSJ0cmFuc2xhdGUiDQogICAgICAgIHZhbHVlcz0iMCAwOyAwIDIwOyAwIDAiDQogICAgICAgIGJlZ2luPSIwIiBkdXI9IjEuMnMiIHJlcGVhdENvdW50PSJpbmRlZmluaXRlIiAvPg0KICAgIDwvcmVjdD4NCiAgICA8cmVjdCB4PSIxMCIgeT0iMCIgd2lkdGg9IjQiIGhlaWdodD0iMTAiIGZpbGw9IiNmZjY2NTgiPg0KICAgICAgPGFuaW1hdGVUcmFuc2Zvcm0gYXR0cmlidXRlVHlwZT0ieG1sIg0KICAgICAgICBhdHRyaWJ1dGVOYW1lPSJ0cmFuc2Zvcm0iIHR5cGU9InRyYW5zbGF0ZSINCiAgICAgICAgdmFsdWVzPSIwIDA7IDAgMjA7IDAgMCINCiAgICAgICAgYmVnaW49IjAuMnMiIGR1cj0iMS4ycyIgcmVwZWF0Q291bnQ9ImluZGVmaW5pdGUiIC8+DQogICAgPC9yZWN0Pg0KICAgIDxyZWN0IHg9IjIwIiB5PSIwIiB3aWR0aD0iNCIgaGVpZ2h0PSIxMCIgZmlsbD0iI2ZmNjY1OCI+DQogICAgICA8YW5pbWF0ZVRyYW5zZm9ybSBhdHRyaWJ1dGVUeXBlPSJ4bWwiDQogICAgICAgIGF0dHJpYnV0ZU5hbWU9InRyYW5zZm9ybSIgdHlwZT0idHJhbnNsYXRlIg0KICAgICAgICB2YWx1ZXM9IjAgMDsgMCAyMDsgMCAwIg0KICAgICAgICBiZWdpbj0iMC40cyIgZHVyPSIxLjJzIiByZXBlYXRDb3VudD0iaW5kZWZpbml0ZSIgLz4NCiAgICA8L3JlY3Q+DQogIDwvc3ZnPg==");
    background-repeat: no-repeat;
    background-position: center;
    margin: 0;
    animation: none; }
    .main-section .gallery-container .gallery .swiper-lazy-preloader:after {
      display: none; }
  .main-section .gallery-container .gallery .swiper-button-prev, .main-section .gallery-container .gallery .swiper-button-next {
    display: none; }
    .main-section .gallery-container .gallery .swiper-button-prev.not-ready, .main-section .gallery-container .gallery .swiper-button-next.not-ready {
      display: none !important; }
    @media all and (min-width: 960px) {
      .main-section .gallery-container .gallery .swiper-button-prev:not(.swiper-button-lock), .main-section .gallery-container .gallery .swiper-button-next:not(.swiper-button-lock) {
        display: block; } }

.main-section .article {
  line-height: 2em; }
  .main-section .article .small {
    font-size: .875em; }

span.bar {
  display: inline-block;
  width: 3px;
  height: 6px;
  background-color: #ff6658;
  margin-right: 6px;
  margin-left: 6px; }
  span.bar:first-child {
    margin-left: 0; }
  span.bar:last-child {
    margin-right: 0; }

.with-bar {
  display: inline-flex;
  align-items: baseline; }
  .with-bar:before {
    content: "";
    width: 3px;
    height: 6px;
    margin-right: 6px;
    background-color: #6c6863; }
  .with-bar.on:before {
    background-color: #ff6658; }

.browsehappy {
  position: absolute;
  top: 0;
  right: 0;
  padding: 6px;
  font-size: 12px;
  text-transform: initial; }
  .browsehappy a {
    color: #ff6658; }

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  background-image: url("data:image/svg+xml;charset=utf-8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAyNyA0NCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjcgNDQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+DQoJLnN0MHtmaWxsOiNGRjY3NUQ7fQ0KPC9zdHlsZT4NCjxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik0xNy44LDI1aC05di02aDlWMjV6Ii8+DQo8L3N2Zz4="); }

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  background-image: url("data:image/svg+xml;charset=utf-8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyMi4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAyNyA0NCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjcgNDQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+DQoJLnN0MHtmaWxsOiNGRjY3NUQ7fQ0KPC9zdHlsZT4NCjxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik0xNy44LDI2LjVoLTl2LTloOVYyNi41eiIvPg0KPC9zdmc+"); }

body {
  font-family: "Hoefler Text A", "Hoefler Text B";
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase; }

main * {
  font-size: .75rem;
  letter-spacing: 2px; }

i, em {
  font-family: "Hoefler Text A", "Hoefler Text B";
  font-style: italic; }

b, strong {
  font-family: "Hoefler Text A", "Hoefler Text B";
  font-weight: 700; }

#privacybanner {
  display: none;
  position: fixed;
  justify-content: center;
  align-items: center;
  border-radius: 1em;
  top: calc(50% - 150px);
  left: calc(50% - 150px);
  z-index: 1000;
  width: 300px;
  height: 300px;
  padding: 20px;
  box-sizing: border-box;
  background-color: #000;
  color: #eee; }
  #privacybanner.on {
    display: flex; }
  #privacybanner > div {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  #privacybanner p {
    margin: 0;
    font-size: 11px;
    line-height: 16px; }
    #privacybanner p a {
      color: #bbb030; }
  #privacybanner .close-privacy {
    display: block;
    flex-basis: 30px;
    flex-shrink: 0;
    margin-left: 8px;
    width: 30px;
    height: 30px;
    background: transparent url("../img/close-btn-white.png") top left no-repeat;
    text-indent: 100%;
    overflow: hidden;
    outline: 0;
    cursor: pointer; }
